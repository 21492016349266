var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mobile)?_c('mobile-download'):_c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0",staticStyle:{"width":"600px !important"}},[_c('div',{staticClass:"connection__light float-right",class:!_vm.connection ? 'is_connection_failed' : ''}),_c('b-link',{staticClass:"brand-logo"},[_c('h1',{staticClass:"main-logo",on:{"click":function($event){_vm.$router.push({name: 'home'}).catch(function () {})}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.Logo,"alt":"logo"}})])]),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Registeration ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 0 ? false:null,"name":"register-firstname","placeholder":"john"},model:{value:(_vm.register.first_name),callback:function ($$v) {_vm.$set(_vm.register, "first_name", $$v)},expression:"register.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false:null,"name":"register-lastname","placeholder":"doe"},model:{value:(_vm.register.last_name),callback:function ($$v) {_vm.$set(_vm.register, "last_name", $$v)},expression:"register.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.register.email),callback:function ($$v) {_vm.$set(_vm.register, "email", $$v)},expression:"register.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone","placeholder":"+000 0000 0000"},model:{value:(_vm.register.phone),callback:function ($$v) {_vm.$set(_vm.register, "phone", $$v)},expression:"register.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Profession","label-for":"profession"}},[_c('validation-provider',{attrs:{"name":"profession","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"profession","state":errors.length > 0 ? false:null,"name":"profession","placeholder":"Enter profession"},model:{value:(_vm.register.profession),callback:function ($$v) {_vm.$set(_vm.register, "profession", $$v)},expression:"register.profession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.register.password),callback:function ($$v) {_vm.$set(_vm.register, "password", $$v)},expression:"register.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirm_password"}},[_c('validation-provider',{attrs:{"name":"Confirm_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm_password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-confirm_password","placeholder":"············"},on:{"input":_vm.handleConfirmPasswordCheck},model:{value:(_vm.register.confirm_password),callback:function ($$v) {_vm.$set(_vm.register, "confirm_password", $$v)},expression:"register.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.start_password_check)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.password_check_message))]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null,"name":"register-address","placeholder":"address"},model:{value:(_vm.register.address1),callback:function ($$v) {_vm.$set(_vm.register, "address1", $$v)},expression:"register.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","state":errors.length > 0 ? false:null,"name":"register-city","placeholder":"City"},model:{value:(_vm.register.city),callback:function ($$v) {_vm.$set(_vm.register, "city", $$v)},expression:"register.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"State","label-for":"state"}},[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"state","state":errors.length > 0 ? false:null,"name":"register-state","placeholder":"State"},model:{value:(_vm.register.state),callback:function ($$v) {_vm.$set(_vm.register, "state", $$v)},expression:"register.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"Zip Code","label-for":"zipcode"}},[_c('validation-provider',{attrs:{"name":"zipcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"zipcode","state":errors.length > 0 ? false:null,"name":"register-zipcode","placeholder":"Zip Code"},model:{value:(_vm.register.zip_code),callback:function ($$v) {_vm.$set(_vm.register, "zip_code", $$v)},expression:"register.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{attrs:{"label":"County","label-for":"county"}},[_c('validation-provider',{attrs:{"name":"county","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"county","state":errors.length > 0 ? false:null,"name":"register-county","placeholder":"County"},model:{value:(_vm.register.county),callback:function ($$v) {_vm.$set(_vm.register, "county", $$v)},expression:"register.county"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-form-group',{staticClass:"mt-2 mb-2"},[_c('validation-provider',{attrs:{"name":"terms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","state":errors.length > 0 ? false:null,"name":"checkbox-1"},model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}},[_vm._v(" I agree to "),_c('b-link',{attrs:{"to":"/privacy-policy","target":"_blank"}},[_vm._v(" privacy policy ")]),_vm._v(" & "),_c('b-link',{attrs:{"to":"terms","target":"_blank"}},[_vm._v(" terms ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"auth_button_view"},[_c('b-button',{attrs:{"variant":"outline-primary","type":"submit"}},[_vm._v(" Register ")])],1)],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v("Login instead")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }