<template>
  <mobile-download v-if="mobile" />
  <div
    v-else
    class="auth-wrapper auth-v1 px-2"
  >
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card
        class="mb-0"
        style="width: 600px !important"
      >
        <div
          class="connection__light float-right"
          :class="!connection ? 'is_connection_failed' : ''"
        />
        <b-link class="brand-logo">
          <h1
            class="main-logo"
            @click="$router.push({name: 'home'}).catch(() => {})"
          >
            <img
              :src="Logo"
              class="img-fluid"
              alt="logo"
            >
          </h1>
        </b-link>

        <b-card-title class="mb-1">
          Registeration
        </b-card-title>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- username -->
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  label="First Name"
                  label-for="firstname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="firstname"
                    rules="required"
                  >
                    <b-form-input
                      id="firstname"
                      v-model="register.first_name"
                      :state="errors.length > 0 ? false:null"
                      name="register-firstname"
                      placeholder="john"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </div>

              <div class="col-md-6">
                <b-form-group
                  label="Last Name"
                  label-for="lastname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="lastname"
                    rules="required"
                  >
                    <b-form-input
                      id="lastname"
                      v-model="register.last_name"
                      :state="errors.length > 0 ? false:null"
                      name="register-lastname"
                      placeholder="doe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </div>
              <div class="col-md-12">
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="register.email"
                      :state="errors.length > 0 ? false:null"
                      name="register-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-md-12">
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-form-input
                      id="phone"
                      v-model="register.phone"
                      :state="errors.length > 0 ? false:null"
                      name="phone"
                      placeholder="+000 0000 0000"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group
                  label="Profession"
                  label-for="profession"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="profession"
                    rules="required"
                  >
                    <b-form-input
                      id="profession"
                      v-model="register.profession"
                      :state="errors.length > 0 ? false:null"
                      name="profession"
                      placeholder="Enter profession"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <!-- password -->
                <b-form-group
                  label="Password"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="register.password"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </div>

              <div class="col-md-12">
                <!-- password -->
                <b-form-group
                  label="Confirm Password"
                  label-for="confirm_password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm_password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="confirm_password"
                        v-model="register.confirm_password"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="register-confirm_password"
                        placeholder="············"
                        @input="handleConfirmPasswordCheck"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="start_password_check"
                      class="text-danger"
                    >{{ password_check_message }}</small>
                  </validation-provider>
                </b-form-group>

              </div>

              <div class="col-md-12">
                <b-form-group
                  label="Address"
                  label-for="address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="address"
                    rules="required"
                  >
                    <b-form-input
                      id="address"
                      v-model="register.address1"
                      :state="errors.length > 0 ? false:null"
                      name="register-address"
                      placeholder="address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-3">
                <b-form-group
                  label="City"
                  label-for="city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="register.city"
                      :state="errors.length > 0 ? false:null"
                      name="register-city"
                      placeholder="City"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-3">
                <b-form-group
                  label="State"
                  label-for="state"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="state"
                    rules="required"
                  >
                    <b-form-input
                      id="state"
                      v-model="register.state"
                      :state="errors.length > 0 ? false:null"
                      name="register-state"
                      placeholder="State"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-3">
                <b-form-group
                  label="Zip Code"
                  label-for="zipcode"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="zipcode"
                    rules="required"
                  >
                    <b-form-input
                      id="zipcode"
                      v-model="register.zip_code"
                      :state="errors.length > 0 ? false:null"
                      name="register-zipcode"
                      placeholder="Zip Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-3">
                <b-form-group
                  label="County"
                  label-for="county"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="county"
                    rules="required"
                  >
                    <b-form-input
                      id="county"
                      v-model="register.county"
                      :state="errors.length > 0 ? false:null"
                      name="register-county"
                      placeholder="County"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>

            <!-- checkbox -->
            <b-form-group class="mt-2 mb-2">
              <validation-provider
                #default="{ errors }"
                name="terms"
                rules="required"
              >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="terms"
                  :state="errors.length > 0 ? false:null"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link
                    to="/privacy-policy"
                    target="_blank"
                  >
                    privacy policy
                  </b-link> & <b-link
                    to="terms"
                    target="_blank"
                  >
                    terms
                  </b-link>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="auth_button_view">
              <!-- submit button -->
              <b-button
                class=""
                variant="outline-primary"
                type="submit"
              >
                Register
              </b-button>
            </div>

          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Login instead</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import checkScreenOsMixins from '@/mixins/checkScreenOsMixins.js'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import MobileDownload from '../../MobileDownload.vue'
import Logo from '../../assets/images/logo/logo.png'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    MobileDownload,
  },
  mixins: [togglePasswordVisibility, checkScreenOsMixins],
  data() {
    return {
      Logo,
      regEmail: '',
      username: '',
      password: '',
      terms: '',
      register: {
        first_name: '',
        last_name: '',
        password: '',
        confirm_password: '',
        phone: '',
        email: '',
        user_type: 'member',
        address1: '',
        city: '',
        state: '',
        zip_code: '',
        county: '',
        profession: '',
      },
      start_password_check: false,
      password_check_message: 'Password does not match!',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    connection() {
      return this.$store.state.app.connection
    },
  },
  beforeMount() {
    this.checkScreen()
  },
  mounted() {
    this.checkScreen()
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/registerMemeber', this.register)
        }
      })
    },
    handleConfirmPasswordCheck() {
      if (this.register.password !== this.register.confirm_password) {
        this.start_password_check = true
      } else {
        this.start_password_check = false
      }

      if (this.register.confirm_password.length < 1) {
        this.start_password_check = false
      }
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
